// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-awards-tsx": () => import("./../../../src/pages/awards.tsx" /* webpackChunkName: "component---src-pages-awards-tsx" */),
  "component---src-pages-homepage-awards-section-tsx": () => import("./../../../src/pages/homepage/awards-section.tsx" /* webpackChunkName: "component---src-pages-homepage-awards-section-tsx" */),
  "component---src-pages-homepage-company-section-tsx": () => import("./../../../src/pages/homepage/company-section.tsx" /* webpackChunkName: "component---src-pages-homepage-company-section-tsx" */),
  "component---src-pages-homepage-contact-us-section-tsx": () => import("./../../../src/pages/homepage/contact-us-section.tsx" /* webpackChunkName: "component---src-pages-homepage-contact-us-section-tsx" */),
  "component---src-pages-homepage-projects-section-tsx": () => import("./../../../src/pages/homepage/projects-section.tsx" /* webpackChunkName: "component---src-pages-homepage-projects-section-tsx" */),
  "component---src-pages-homepage-staff-section-tsx": () => import("./../../../src/pages/homepage/staff-section.tsx" /* webpackChunkName: "component---src-pages-homepage-staff-section-tsx" */),
  "component---src-pages-homepage-title-section-tsx": () => import("./../../../src/pages/homepage/title-section.tsx" /* webpackChunkName: "component---src-pages-homepage-title-section-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-project-gallery-tsx": () => import("./../../../src/templates/project-gallery.tsx" /* webpackChunkName: "component---src-templates-project-gallery-tsx" */)
}

